import React from 'react'
import './Service.css'
import Image15 from '../../images/doc.jpg'

const Service = () => {
  return (
    <div>
      <div className="choice-container">
                <div className="choice-head">Achieve Your Full Potential in MBBS with UniversalMBBS</div>
                <div className="home-container">
                    <div className="circular-image">
                    <img src={Image15} alt="Architecture" id='why' />
                      
                    </div>
                    <div className="rotate-image"></div>
                    <div className="light-background home-interior">
                        <h3>Admissions Counseling Tailored to You</h3>
                        <p>Gaining admission into top MBBS programs requires thorough preparation and strategic planning. Our expert consultants get to know your academic background, interests, and career goals, then provide personalised guidance optimised for your profile.</p>
                    </div>
                    <div className="dark-background home-affordable">
                        {/* <h3>Uzbekistan</h3> */}
                        <p>We help you research the medical colleges and programs that are the best fit, brainstorm what makes you stand out as an applicant, and critique your application essays and interview answers </p>
                    </div>
                    <div className="light-background home-guaranteed">
                        <h3>Support Throughout Your MBBS Journey</h3>
                        <p>Our services continue once you secure admission. UniversalMBBS provides ongoing guidance and assistance throughout your MBBS degree to help you excel academically. </p>
                    </div>
                    <div className="dark-background home-number">
                        {/* <h3>Kazakhstan</h3> */}
                        <p>Managing rigorous curricula across semesters and clinical rotations can be demanding. We advise you to navigate course requirements efficiently, connect concepts across subjects, balance patient responsibilities with academics, and pursue research or publication opportunities.</p>
                    </div>
                    <div className="light-background home-consultation">
                        {/* <h3>Serbia</h3> */}
                        <p>We also provide networking channels with doctors who can offer insider tips for success. With our support system backing you, completing your MBBS will be smoother.  </p>
                    </div>
                    <div className="dark-background home-support">
                        {/* <h3>Armenia</h3> */}
                        <p>We provide full-length mock tests and in-depth performance reviews to help analyse areas for improvement. Our test database covers the complete NEET syllabus so you can practice extensively with questions modelled closely on the exam.</p>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default Service