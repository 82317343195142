import React from 'react'
import './Card.css'
const Card = () => {
  return (
    <div>
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img class="object-cover object-center rounded" alt="hero" src="https://nypost.com/wp-content/uploads/sites/2/2019/04/doctor-gloves-feature.jpg?quality=90&strip=all&w=1200"></img>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-12 flex flex-col md:items-start md:text-left items-center text-center">
      <div className='text1'>
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Know more about us!!!
      </h1>
      </div>
      <div className='text'>
      <p class="mb-2 leading-relaxed">Universalmbbs.com is a part of Orinova Edutech Private Limited. </p>
      <p class="mb-2 leading-relaxed">We are in education industry more than 10 years where we work in the field of different education stream like Engineering , medical ,Management and  courses in India and abroad .</p>
      <p class="mb-2 leading-relaxed">We have successfully guided and counselled more 5200 students from different streams in past 10 years which is now successful and well settle in their profession.</p>
      <p class="mb-2 leading-relaxed">Universalmbbs.com is work with one motto to fulfill the aspirants dream to become a successful doctor where they server the society. we guiding them and providing valuable guidance so that they can study in India or abroad with peace of mind and they focus only on education.</p>
      </div>
      </div>


    </div>
</section>
    

{/* <section class="timeline">
  <ol>
    <li>
      <div>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
      </div>
    </li>
    <li>
      <div>
         Proin quam velit, efficitur vel neque vitae, rhoncus commodo mi. Suspendisse finibus mauris et bibendum molestie. Aenean ex augue, varius et pulvinar in, pretium non nisi.
      </div>
    </li>
    <li>
      <div>
        Proin iaculis, nibh eget efficitur varius, libero tellus porta dolor, at pulvinar tortor ex eget ligula. Integer eu dapibus arcu, sit amet sollicitudin eros.
      </div>
    </li>
    <li>
      <div>
        In mattis elit vitae odio posuere, nec maximus massa varius. Suspendisse varius volutpat mattis. Vestibulum id magna est.
      </div>
    </li>
    <li>
      <div>
        In mattis elit vitae odio posuere, nec maximus massa varius. Suspendisse varius volutpat mattis. Vestibulum id magna est.
      </div>
    </li>
    <li>
      <li> </li>
    </li>
   
     <li></li>
  </ol>
</section> */}
      </div>

  
  );
};

export default Card;