import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { FaMobileAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import '../Services.css'
import Logo from '../../images/logo.png'
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
    return (
        <>
           
            <div className="services-footer">
                <div className="services-footer-info">
                    <div className="services-logo-image">
                        <img src={Logo} alt="logo" /></div>
                    <p>At UNIVERSALMBBS, we are committed to providing the highest quality services to our clients. We maintain a strong network of partner universities across the globe, ensuring that our students have access to the best education and training opportunities. We also offer pre-departure and post-arrival support to ensure a smooth transition for our students.</p>
                </div>
                <div className="services-services">
                    <div className="services-services-head"><h3>SERVICES</h3></div>
                    <p>
                        <ul>
                            <li><Link to="/mbbs/home">Home</Link></li>
                            <li><Link to="/mbbs/about">About Us</Link></li>
                            <li><Link to="/mbbs/galery">Gallery</Link></li>
                            <li><Link to="/mbbs/destination">Destination</Link></li>
                            <li><Link to="/mbbs/contact">Contact Us</Link></li>
                        </ul>
                    </p>
                </div>
                <div className="services-contact-us">
                    <div className="services-contact-head"><h3>CONTACT US</h3></div>
                    <div className="services-contacts">
                        <FaLocationDot className='services-address' />
                        <p>Address:  Sadhna bhavan L-3rd/83, Sector -D, Aliganj,Near Puraniya chouraha,  Lucknow, Uttar Pradesh -226024 , landmark - backside of  UPPSC building,</p>
                    </div>
                    <div className="services-contacts">
                        <FaMobileAlt className='services-phone' />
                        <p>Mobile: +91-8210400672/ +91-8709001966</p>
                    </div>
                    <div className="services-contacts">
                        <MdEmail className='services-email' />
                        <p>Email: admission@universalmbbs.com, Info@universalmbbs.com</p>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer