import React from 'react'
import './Timeline.css'

const Timeline = () => {
  return (
    <div>
        

<section class="text-gray-600 body-font bg-slate-200">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">MBBS Destination</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Our path, your destination</p>
    </div>
    <div class="flex flex-wrap -m-2">
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
        <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4 s" src="https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Kazakhstan.png" ></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> Kazakhstan</h2>
            {/* <p class="text-gray-500">With its rapidly growing reputation in medicine, Kazakhstan has become a preferred choice for international students seeking quality education at affordable prices</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://th.bing.com/th/id/R.e0571461ff38907bb441a6c2f664716f?rik=pTQNyUFB0zbTMA&riu=http%3a%2f%2fwww.theodora.com%2fflags%2fnew15%2frussia_flag_large.jpg&ehk=zSiXHrmwBD4azFow%2fgwgagv8GGKSpa4TE1ztSKvRtM4%3d&risl=&pid=ImgRaw&r=0"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Russia</h2>
            {/* <p class="text-gray-500">CTO</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://upload.wikimedia.org/wikipedia/commons/1/12/National_Flag_of_Georgia.jpg"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Georgia</h2>
            {/* <p class="text-gray-500">Founder</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://wallpapercave.com/wp/wp2964253.jpg"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">John Doe</h2>
            {/* <p class="text-gray-500">DevOps</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://images5.alphacoders.com/102/1027336.png"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Serbia</h2>
            {/* <p class="text-gray-500">Software Engineer</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://th.bing.com/th/id/OIP.SBmsYUFy0S4S6ObNsIftaQHaEK?pid=ImgDet&rs=1"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Armenia</h2>
            {/* <p class="text-gray-500">UX Researcher</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://th.bing.com/th/id/OIP._egzVZ-kKQSi51EosiT5zAHaEK?pid=ImgDet&rs=1"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Atticus Finch</h2>
            {/* <p class="text-gray-500">QA Engineer</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://www.theflagman.co.uk/wp-content/uploads/2016/09/flag-of-bangladesh.jpg"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Bangladesh</h2>
            {/* <p class="text-gray-500">System</p> */}
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://th.bing.com/th/id/R.fdfc6c118fd51843690e465f7fe19246?rik=ZY009mNKRIkR%2fQ&riu=http%3a%2f%2fwww.all-flags-world.com%2fcountry-flag%2fNepal%2fflag-nepal-XL.jpg&ehk=OqvAcsdnImhZOb%2bRUbOcDfVvZTj2SvzETwhfgLJVtL0%3d&risl=&pid=ImgRaw&r=0"></img>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium">Nepal</h2>
            {/* <p class="text-gray-500">Product Manager</p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <div class="containerc">
        <div class="cardc">
            <div class="face face1">
                <div class="content">
                 
                    <h3>Expert Counselling</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>Our team of experienced counsellors provides personalised guidance to help students achieve their academic goals.</p>
                        
                </div>
            </div>
        </div>
        <div class="cardc">
            <div class="face face1">
                <div class="content">
                   
                    <h3>Wide Network</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>We maintain a strong network of partner universities across the globe, ensuring that our students have access to the best education and training opportunities.</p>
                     
                </div>
            </div>
        </div>
        <div class="cardc">
            <div class="face face1">
                <div class="content">
                   
                    <h3>Hassle-Free Process</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>We provide end-to-end support for the admission process, from university selection to visa application and post-arrival support.</p>
                        
                </div>
            </div>
        </div>

        <div class="cardc">
            <div class="face face1">
                <div class="content">
                   
                    <h3>Competitive Pricing</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p> We offer affordable services without compromising on quality, ensuring that our clients receive the best value for their investment.</p>
                        
                </div>
            </div>
        </div>


       
    </div>


    

    </div>
  );
};

export default Timeline;