import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Timeline from "./components/Timeline/Timeline";
import Card from "./components/Card/Card";
import Footer1 from "./components/Footer/Footer";
import Service from "./components/Service/Service";
import Nav from "./components/Nav/Nav";
import Kazakhstan from "./components/Kazakhstan/Kazakhstan";
import Georgia from "./components/Georgia/Georgia";
import Russia from "./components/Russia/Russia";

function App() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mbbs/about" element={<Card />} />
          <Route path="/mbbs/gallery" element={<Gallery />} />
          <Route path="/mbbs/service" element={<Service />} />
          <Route path="/mbbs/why-choose-us" element={<Timeline />} />
          <Route path="/mbbs/contact" element={<Contact />} />
          <Route path="/mbbs/kazak" element={<Kazakhstan />} />
          <Route path="/mbbs/georgia" element={<Georgia />} />
          <Route path="/mbbs/russia" element={<Russia />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
