import React from 'react'
import './Kazakhstan.css'
import Experience from '../Experience/Experience'

const kazakhstan = () => {
  return (
    <div>
       
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
    <div className='cute1'>
      <img class="object-cover object-center rounded" alt="hero" src="https://th.bing.com/th/id/R.0d43971bdbc9482fb88d7900cc8b8b65?rik=9A%2fSvqu1hLwdtg&riu=http%3a%2f%2faboutkazakhstan.com%2fblog%2fwp-content%2fuploads%2f2016%2f05%2fastana-at-night-kazakhstan-1.jpg&ehk=XSCqqILq1MqZkUtfZIn2RhJr4tIQ7dTU5GGijj1LrCs%3d&risl=&pid=ImgRaw&r=0"></img>
      </div>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-12 flex flex-col md:items-start md:text-left items-center text-center">
      <div className='text1'>
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Pursuing MBBS in Kazakhstan? Consult UniversalMBBS
      </h1>
      </div>
      <div className='text'>
      <p class="mb-2 leading-relaxed">With over 15 years of experience, we have guided thousands of Indian students through every step of undertaking medical education in Kazakhstan - right from university selection and admission process to visa assistance, travel planning, and more. </p>
      <p class="mb-2 leading-relaxed">With over 15 years of experience, we have guided thousands of Indian students through every step of undertaking medical education in Kazakhstan - right from university selection and admission process to visa assistance, travel planning, and more. </p>

      {/* <p class="mb-2 leading-relaxed">We are in education industry more than 10 years where we work in the field of different education stream like Engineering , medical ,Management and  courses in India and abroad .</p>
      <p class="mb-2 leading-relaxed">We have successfully guided and counselled more 5200 students from different streams in past 10 years which is now successful and well settle in their profession.</p>
      <p class="mb-2 leading-relaxed">Universalmbbs.com is work with one motto to fulfill the aspirants dream to become a successful doctor where they server the society. we guiding them and providing valuable guidance so that they can study in India or abroad with peace of mind and they focus only on education.</p> */}
      </div>
      </div>


    </div>
</section>
<div>

<h2 className='heading'>Why you should choose UniversalMBBS as your MBBS partner in</h2>
<h2 className='heading' data-aos="zoom-in-down" data-aos-offset="200" data-aos-delay="1500"
    data-aos-duration="1500"> <span3>Kazakhstan?</span3></h2>
</div>
    

<section class="timeline" >
    <div className='class'data-aos="fade-up"  data-aos-offset="200" data-aos-delay="100"
    data-aos-duration="1500">
  <ol>
    <li>
      <div>
      •	We take time to understand your academic background, interests, and career aspirations before suggesting universities and programs in Kazakhstan that are the right fit for you.
      </div>
    </li>
    <li>
      <div>
      •	Our experts provide end-to-end guidance on drafting a strong application profile, reviewing your documents, application submission, follow-ups with universities, and interview preparation customized for Kazakh institutions.
      </div>
    </li>
    <li>
      <div>
      •	We assist with the complete Kazakhstan student visa process including advising on required documents, health certificates, visa interviews, and more.
      </div>
    </li>
    <li>
      <div>
      •	Our pre-departure guidance and on-ground support in Kazakhstan helps you transition smoothly into life as a medical student there.</div>
    </li>
    <li>
      <div>
      •	Even after you begin your MBBS, we continue assisting you with attendance records, exam applications, clinical rotations, offers for student exchanges, and more.
      </div>
    </li>
    <li>
      <li> </li>
    </li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
     <li></li>
  </ol>
  </div>
</section>


<Experience/>


      </div>

  
  );
};

export default kazakhstan;