import React from 'react'
import './Russia.css'
import Experience2 from '../Experience2/Experience2'

const Russia = () => {
  return (
    <div>
       
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
    <div className='cute1'>      
      <img class="object-cover object-center rounded" alt="hero" src="https://th.bing.com/th/id/R.0d43971bdbc9482fb88d7900cc8b8b65?rik=9A%2fSvqu1hLwdtg&riu=http%3a%2f%2faboutkazakhstan.com%2fblog%2fwp-content%2fuploads%2f2016%2f05%2fastana-at-night-kazakhstan-1.jpg&ehk=XSCqqILq1MqZkUtfZIn2RhJr4tIQ7dTU5GGijj1LrCs%3d&risl=&pid=ImgRaw&r=0"></img>
      </div>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-12 flex flex-col md:items-start md:text-left items-center text-center">
      <div className='text1'>
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Pursuing MBBS in Russia? Consult UniversalMBBS
      </h1>
      </div>
      <div className='text'>
      <p class="mb-2 leading-relaxed">With over a decade of experience, we have helped thousands of Indian students fulfill their dream of becoming doctors by getting accepted into top Russian medical universities </p>
      {/* <p class="mb-2 leading-relaxed">With over 15 years of experience, we have guided thousands of Indian students through every step of undertaking medical education in Kazakhstan - right from university selection and admission process to visa assistance, travel planning, and more. </p> */}

      {/* <p class="mb-2 leading-relaxed">We are in education industry more than 10 years where we work in the field of different education stream like Engineering , medical ,Management and  courses in India and abroad .</p>
      <p class="mb-2 leading-relaxed">We have successfully guided and counselled more 5200 students from different streams in past 10 years which is now successful and well settle in their profession.</p>
      <p class="mb-2 leading-relaxed">Universalmbbs.com is work with one motto to fulfill the aspirants dream to become a successful doctor where they server the society. we guiding them and providing valuable guidance so that they can study in India or abroad with peace of mind and they focus only on education.</p> */}
      </div>
      </div>


    </div>
</section>
<div>

<h2 className='heading'>Why you should choose UniversalMBBS as your MBBS partner in</h2>
<h2 className='heading' data-aos="zoom-in-down" data-aos-offset="200" data-aos-delay="1500"
    data-aos-duration="1500"> <span3>Russia?</span3></h2>
</div>
    

<section class="timeline" >
    <div className='class'data-aos="fade-up"  data-aos-offset="200" data-aos-delay="100"
    data-aos-duration="1500">
  <ol>
    <li>
      <div>
        <h1>Insider Knowledge of Top Medical Universities</h1>
      Our consultants personally visit and evaluate MBBS colleges across Georgia. This gives us in-depth understanding of infrastructure, curriculum, faculty, fees, rankings and more.
      </div>
    </li>
    <li>
      <div>
        <h1>Personalized Guidance Tailored to You</h1>
      •We take time to understand your academic background, interests, future specialization goals and personality before advising Russia MBBS programs that align with your profile. Our one-on-one counseling focuses on you as an individual - we don’t take a cookie-cutter approach.
      </div>
    </li>
    <li>
      <div>
        <h1>End-To-End Application Support</h1>
      •	After helping you shortlist target colleges, we guide you through the complete application process – from eligibility criteria to document preparation, application submission, following up with universities and more.
      </div>
    </li>
    <li>
      <div>
        <h1>Visa Assistance and Travel Planning</h1>
      •Obtaining the right visas and planning your relocation to Russia can seem daunting. We advise on the visa categories and application documents required. We also provide complete travel support - flight bookings, foreign exchange, insurance, packing, and more. </div>
    </li>
    <li>
      <div>
        <h1> Ongoing Support System</h1>
      •Even after you arrive in Russia, UniversalMBBS continues supporting you. We help with college enrollment formalities, arranging suitable accommodation, student essentials shopping, orientation to the campus & city, and more. 
      </div>
    </li>
    <li>
      <li> </li>
    </li>
    <li></li>
    <li></li>
    <li></li>   
    <li></li>
     <li></li>
  </ol>
  </div>
</section>


<Experience2/>


      </div>

  
  );
};

export default Russia;