import React from 'react'
import './Home.css'
import { Link } from "react-router-dom";
import Image1 from '../../images/img2.jpeg'
import Image2 from '../../images/doc1.jpg'
import Image4 from '../../images/img3.JPG'
import Image5 from '../../images/time.jpg'
import Image3 from '../../images/doc.jpg'
import Image6 from '../../images/about.jpeg'
import Image7 from '../../images/images.jpeg'
// import Image15 from '../../images/doc.jpg'

import { Typewriter } from 'react-simple-typewriter'
import '../Services.css'



const Home = () => {
    return (
        <>
            <div id="slider">
                <figure>
                    <img src={Image1} alt='project' />
                    <img src={Image2} alt='project' />
                    <img src={Image3} alt='project' />
                    <img src={Image4} alt='project' />
                    <img src={Image5} alt='project' />
                </figure>



                <div className='text'>The first step towards career success.
</div>
                {/* <div className="small-text">Transform your home or office space into an elegant and functional oasis with our expert interior design services. We tailor solutions to your preferences.</div> */}
                <Link to='/mbbs/home'><button className='btn'>Download Brochure</button></Link>
                <Link to='/mbbs/home'><button className='btn1'>Apply Now</button></Link>
            </div>

            <div className="gallery-container">
                <div className="display">
                <div className="consultant-form">
                        <form action="#" data-aos="slide-up" data-aos-offset="300" data-aos-duration="1500">
                            <div className="form-head"><h3>Contact Us</h3></div>
                            <div className="name">
                            <input type="text" name="name" id="name" placeholder='Enter your name' />
                        </div>
                        <div className="email">
                            <input type="email" name="email" id="email" placeholder='Enter your email' />
                        </div>
                        <div className="tel">
                            <input type="tel" name="phone" id="phone" placeholder='Enter your mobile number' />
                        </div>
                        <div className="score">
                            <input type="number" name="score" id="score" placeholder='Enter your neet score' />
                        </div>
                        <div className="country">
                            <input type="text" name="country" id="country" placeholder='Enter your current country' />
                        </div>
                        <div className="submit">
                            <input type="submit" value="Enquire Now" />
                        </div>
                        </form>
                    </div>
                    </div>
                    </div>


           


            <div className="info">
                OUR <span id='we'>GOALS</span> 
            </div>

            <div className="home-cards">
                <div className="home-card">
                    <img src={Image6} alt="Residential" />
                    <div className='home-card-head'>
                        OUR <span>MISSION</span>
                    </div>
                    <div className='home-card-info'>
                        <p>Our mission is to empower students to achieve their academic goals by providing expert counselling and guidance on MBBS admissions abroad. </p>
                    </div>
                </div>
                <div className="home-card">
                    <img src={Image7} alt="Commercial" />
                    <div className='home-card-head'>
                        OUR <span>VISION</span>
                    </div>
                    <div className='home-card-info'>
                        <p>Our vision is to become a leading provider of MBBS admission services in India, recognised for our expertise, professionalism, and commitment to excellence. </p>
                    </div>
                </div>
               

            </div>



            <div className="services-typewriter">
                <div className="services-best">WE ARE THE "
                    <span>
                        <Typewriter
                            words={['NO.1 MBBS ADMISSION PROVIDER', 'NO.1 MBBS ADMISSION PROVIDER']}
                            loop={false}
                            cursor
                            cursorStyle='|'
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={1000}
                        />
                    </span> "
                </div>
            </div>
          
            
            
            
              
        </>
    )
}

export default Home