import React from 'react'
import './Nav.css'

const Nav = () => {
  return (
    <div className='nav1'>
    <div className='text2'><span class="material-symbols-outlined">
</span>Call: (+91) 7970516431</div>
    <div  className='text2'>|</div>
    <div  className='text2'>Email: admission@universalmbbs.com</div>
    <div className='text2'>| Info@universalmbbs.com</div>
    </div>
  )
}

export default Nav