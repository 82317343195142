import React from 'react'
import './Contact.css'
import Image1 from '../../images/contact.jpeg'
import Image2 from '../../images/contact.jpeg'


const Contact = () => {
    return (
        <>
            <div className="services-display">
                <img src={Image1} alt="living-room" />
                <img src={Image2} alt="living-room" />
                <div className="display-color"></div>
                <p>CONTACT US</p>
            </div>
            <div className="contact-form-container">
                <div className="consultant-form">
                    <form action="#" data-aos="slide-up" data-aos-offset="200" data-aos-delay="100"
    data-aos-duration="1500">
                        <div className="form-head"><h3>Contact Us</h3></div>
                        <div className="name">
                            <input type="text" name="name" id="name" placeholder='Enter your name' />
                        </div>
                        <div className="email">
                            <input type="email" name="email" id="email" placeholder='Enter your email' />
                        </div>
                        <div className="tel">
                            <input type="tel" name="phone" id="phone" placeholder='Enter your mobile number' />
                        </div>
                        <div className="score">
                            <input type="number" name="score" id="score" placeholder='Enter your neet score' />
                        </div>
                        <div className="country">
                            <input type="text" name="country" id="country" placeholder='Enter your current country' />
                        </div>
                        <div className="submit">
                            <input type="submit" value="Enquire Now" />
                        </div>
                    </form>
                </div>
            </div>
            
        </>
    )
}

export default Contact