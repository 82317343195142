import React from 'react'

const Gallery = () => {
  return (
    <div>
        <section class="text-gray-600 body-font bg-slate-300">
  <div class="container px-5 py-14 mx-auto flex flex-wrap center">
    <div class="flex w-full mb-25 flex-wrap">
      <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">Future Gallery</h1>
      {/* <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">Be part of our journey and gallery</p> */}
    </div>
    <div class="flex flex-wrap md:-m-1 -m-1">
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src="https://th.bing.com/th/id/OIP.W03FbeVgTEM7Nx9Fn8X0FQHaE6?pid=ImgDet&rs=1"></img>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src="https://th.bing.com/th/id/OIP.8Xj9geTIeT2z0yDcp3z4lQHaE8?pid=ImgDet&rs=1"></img>
        </div>
        <div class="md:p-2 p-1 w-full">
          <img alt="gallery" class="w-full h-full object-cover object-center block" src="https://th.bing.com/th/id/OIP.UAPsz6X9y7FRp_wmjY63EAHaE8?pid=ImgDet&rs=1"></img>
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-full">
          <img alt="gallery" class="w-full h-full object-cover object-center block" src="https://i.pinimg.com/originals/a7/12/5f/a7125f1f390c9e2fb1e6cd08c5570098.jpg"></img>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src="https://qtxasset.com/fiercehealthcare/1546879381/GettyImages-898906336.jpg/GettyImages-898906336.jpg?rE5bLYVKSyDekriOcwRz6Bcda6vj5XPL"></img>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src="https://wallpaperaccess.com/full/1304162.jpg"></img>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default Gallery