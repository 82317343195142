export const works = {
  work: [
    [
      "Semey Medical University",
      "https://bluepeneducationconsultant.com/wp-content/uploads/2021/03/Untitled-design-12.png",
      "Semey Medical University",
      "https://arbdossier.com/",
      "	Strong in general medicine, public health, pharmacy,Strong in general medicine, public health, pharmacy,Globally benchmarked curriculum and teaching standards,Partnerships with medical schools across Europe.",
      "Feb 2023 - Present",
      "Semey in eastern Kazakhstan",
    ],
    [
      "Kazakh National Medical University, Almaty",
      "https://rvanews.com/wp-content/uploads/2014/09/Kazakh-National-Medical-University.jpg",
      "Kazakh National Medical University, Almaty",
      "https://www.bakerhughes.com/",
      "Strong in general medicine, public health, pharmacy.Globally benchmarked curriculum and teaching standards.Partnerships with medical schools across Europe.",
      "Feb 2023 - Present",
      "Almaty",
    ],
    [
      "Karaganda Medical University",
      "https://careeradvice.in/mbbs-abroad/wp-content/uploads/2021/12/kmu-features.jpg",
      "Karaganda Medical University",
      "https://neyx.in",
      "Excellence in general medicine and cardiology.Clinical experience across multispecialty hospitals.•	Competitively priced tuition for international students",
      "Dec 2022 - Feb 2023",
      "Karaganda, central Kazakhstan",
    ],
    [
      "West Kazakhstan Marat Ospanov Medical University",
      "https://www.edufever.com/wp-content/uploads/2020/10/11021228_831454113600980_8574518837949162087_n-min.jpg",
      "West Kazakhstan Marat Ospanov Medical University",
      "https://www.citjo.in",
      "Specialized in general medicine, dentistry, nursing.Focus on problem-based learning.Vibrant campus life with student clubs and activities",
      "Jul 2022 - Oct 2022",
      "Aktobe, western Kazakhstan",
    ],
    [
      "Astana Medical University",
      "https://th.bing.com/th/id/OIP.UVVcAESC2CDzDVpD1a4sPQHaEl?pid=ImgDet&rs=1",
      "Astana Medical University",
      "https://djss4ds.in/",
      "Advanced simulation center for hands-on training.Globally benchmarked medical education curriculum.	Diverse international student community",
      "Oct 2021 - Present",
      "Nursultan (formerly Astana)",
    ],
  ],
};

export const russias = {
  russia: [
    [
      "Volgograd State Medical University",
      "https://th.bing.com/th/id/R.044360a5e2940bcb9409f520c2b9c353?rik=hwTY9fL0EFkhxw&riu=http%3a%2f%2fstudymbbsinrussia.co.in%2fwp-content%2fuploads%2f2015%2f09%2fVolgograd-State-Medical-University-Russia1.jpg&ehk=9%2bGfBVj2AyaUse3zidzHTOOX2llFFRafsMvTHO0MgV4%3d&risl=&pid=ImgRaw&r=0",
      "Volgograd State Medical University",
      "https://arbdossier.com/",
      "Known for pediatrics, oncology, cardiology, and surgery.Option to study in English or Russian language.Spacious campus with hostels, sports facilities, and events",
      "Feb 2023 - Present",
      "Volgograd",
    ],
    [
      "Northern State Medical University",
      "https://rvanews.com/wp-content/uploads/2014/09/Kazakh-National-Medical-University.jpg",
      "Northern State Medical University",
      "https://www.bakerhughes.com/",
      "Strong in general medicine and pediatric disciplines.Focus on practical training from early semesters.Partnerships with European universities.",
      "Feb 2023 - Present",
      "Arkhangelsk",
    ],
    [
      "Bashkir State Medical University",
      "https://careeradvice.in/mbbs-abroad/wp-content/uploads/2021/12/kmu-features.jpg",
      "Bashkir State Medical University",
      "https://neyx.in",
      "Excellence in general medicine and dentistry.Strong emphasis on research and innovation.Vibrant campus life with arts and cultural activities",
      "Dec 2022 - Feb 2023",
      "Ufa City",
    ],
    [
      "Immanuel Kant Baltic Federal University",
      "https://www.edufever.com/wp-content/uploads/2020/10/11021228_831454113600980_8574518837949162087_n-min.jpg",
      "Immanuel Kant Baltic Federal University",
      "https://www.citjo.in",
      "Recognised for tropical medicine and pathobiology.Advanced simulation centre for clinical skills.International student body from 40+ countries",
      "Jul 2022 - Oct 2022",
      "Kaliningrad by the Baltic Sea",
    ],
    [
      "Mari State University",
      "https://th.bing.com/th/id/OIP.UVVcAESC2CDzDVpD1a4sPQHaEl?pid=ImgDet&rs=1",
      "Mari State University",
      "https://djss4ds.in/",
      "Leaders in public health education and research.	Close industry linkages for internships.Active student organisations and support services",
      "Oct 2021 - Present",
      "Yoshkar-Ola city",
    ],
    [
      "Saratov State Medical University",
      "https://th.bing.com/th/id/OIP.UVVcAESC2CDzDVpD1a4sPQHaEl?pid=ImgDet&rs=1",
      "Saratov State Medical University",
      "https://djss4ds.in/",
      "Specialised in general medicine and dentistry.Globally-recognized medical education since 1909.Partnerships with universities across Europe.",
      "Oct 2021 - Present",
      "Saratov State Medical University",
    ],
  ],
};

export const geos = {
  geo: [
    [
      "New Vision University, Tbilisi",
      "https://bluepeneducationconsultant.com/wp-content/uploads/2021/03/Untitled-design-12.png",
      "New Vision University, Tbilisi",
      "https://arbdossier.com/",
      "Specialized in internal medicine, cardiology, neurology.Strong focus on simulation training and digital health.International student body from 30+ countries.",
      "Feb 2023 - Present",
      "Tbilisi",
    ],
    [
      "David Tvildiani Medical University",
      "https://rvanews.com/wp-content/uploads/2014/09/Kazakh-National-Medical-University.jpg",
      "David Tvildiani Medical University",
      "https://www.bakerhughes.com/",
      "Based in Kutaisi, 2nd largest city of Georgia.Excellent clinical training facilities and teaching hospital.Known for quality education in general medicine.Partnerships with medical universities across Europe",
      "Feb 2023 - Present",
      "Kutaisi",
    ],
    [
      "Batumi Shota Rustaveli State University",
      "https://careeradvice.in/mbbs-abroad/wp-content/uploads/2021/12/kmu-features.jpg",
      "Batumi Shota Rustaveli State University",
      "https://neyx.in",
      "Recognized for high-quality medical instruction since 1931.Leading pediatrics, surgery, orthopedics departments.Lively international student organizations on campus.",
      "Dec 2022 - Feb 2023",
      "Black Sea city of Batumi",
    ],
    [
      "Petre Shotadze Tbilisi Medical Academy",
      "https://www.edufever.com/wp-content/uploads/2020/10/11021228_831454113600980_8574518837949162087_n-min.jpg",
      "Petre Shotadze Tbilisi Medical Academy",
      "https://www.citjo.in",
      "Longest serving private medical institution since 1992.Focus on problem-based learning and research.Top ranks in MCI screening test results",
      "Jul 2022 - Oct 2022",
      "Tbilisi",
    ],
    [
      "Akaki Tsereteli State University",
      "https://th.bing.com/th/id/OIP.UVVcAESC2CDzDVpD1a4sPQHaEl?pid=ImgDet&rs=1",
      "Akaki Tsereteli State University",
      "https://djss4ds.in/",
      "Excellence in therapeutic medicine and public health.Extensive partnership network with global universities.Beautiful campus with sports/recreation facilities",
      "Oct 2021 - Present",
      "Kutaisi",
    ],
  ],
};
