import React, { useState } from "react";
import "./Navbar.css";
import { AiFillCaretDown } from "react-icons/ai";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";

const Navbar = () => {
  const [displaydropdown, setDisplaydropdown] = useState(false);
  const [displaydropdown1, setDisplaydropdown1] = useState(false);
  const [style, setStyle] = useState(false);

  const changeStyle = () => {
    setStyle(!style);
    setDisplaydropdown(!displaydropdown);
  };

  return (
    <div className="nav">
      <div className="logo">
        <img src={Logo} alt="" id="logo-image" />
        <Link to="/spacefound_interiors">
          <span> HTUniversal MBBS</span>
        </Link>
      </div>
      <div className="others">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <div class="nav-dropdown">
              <button
                type="button"
                className="nav-dropbtn"
                onClick={() => setDisplaydropdown1(!displaydropdown1)}
              >
                About
                <span className="arrow">
                  <AiFillCaretDown />
                </span>
              </button>
              {displaydropdown1 && (
                <div class="nav-dropdown-content1">
                  <button
                    onClick={() => setDisplaydropdown1(!displaydropdown1)}
                  >
                    <Link to="/mbbs/about">About Us</Link>
                  </button>
                  <button
                    onClick={() => setDisplaydropdown1(!displaydropdown1)}
                  >
                    <Link to="/mbbs/why-choose-us">Why Choose Us</Link>
                  </button>
                </div>
              )}
            </div>
          </li>

          <li>
            <Link to="/mbbs/service">Service</Link>
          </li>
          <li>
            <Link to="/mbbs/gallery">Gallery</Link>
          </li>
          <li>
            <div class="nav-dropdown">
              <button
                type="button"
                className="nav-dropbtn"
                onClick={() => setDisplaydropdown(!displaydropdown)}
              >
                MBBS Destination
                <span className="arrow">
                  <AiFillCaretDown />
                </span>
              </button>
              {displaydropdown && (
                <div class="nav-dropdown-content">
                  <button onClick={() => setDisplaydropdown(!displaydropdown)}>
                    <Link to="/mbbs/kazak">MBBS in Kazakhstan</Link>
                  </button>
                  <button onClick={() => setDisplaydropdown(!displaydropdown)}>
                    <Link to="/mbbs/georgia">MBBS In Georgia</Link>
                  </button>
                  <button onClick={() => setDisplaydropdown(!displaydropdown)}>
                    <Link to="/mbbs/russia">MBBS In Russia</Link>
                  </button>
                </div>
              )}
            </div>
          </li>

          <li>
            <Link to="/mbbs/contact">Contact</Link>
          </li>
        </ul>
      </div>
      {style && (
        <div className="others-display-list">
          <ul>
            <li>
              <button onClick={() => setStyle(!style)}>
                <Link to="/spacefound_interiors">Home</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setStyle(!style)}>
                <Link to="/spacefound_interiors/gallery">Gallery</Link>
              </button>
            </li>
            <li>
              <button>
                <div class="nav-dropdown">
                  <button
                    type="button"
                    className="nav-dropbtn"
                    onClick={() => setDisplaydropdown(!displaydropdown)}
                  >
                    Services
                    <span className="arrow">
                      <AiFillCaretDown />
                    </span>
                  </button>
                  {displaydropdown && (
                    <div class="nav-dropdown-content">
                      <button onClick={changeStyle}>
                        <Link to="/spacefound_interiors/residential">
                          MBBS In Russia
                        </Link>
                      </button>
                      <button onClick={changeStyle}>
                        <Link to="/spacefound_interiors/living-room">
                          MBBS In Russia
                        </Link>
                      </button>
                      <button onClick={changeStyle}>
                        <Link to="/spacefound_interiors/kitchen">
                          MBBS In Russia
                        </Link>
                      </button>
                      <button onClick={changeStyle}>
                        <Link to="/spacefound_interiors/bathroom">
                          MBBS In Russia
                        </Link>
                      </button>
                      <button onClick={changeStyle}>
                        <Link to="/spacefound_interiors/balcony">
                          MBBS In Russia
                        </Link>
                      </button>
                    </div>
                  )}
                </div>
              </button>
            </li>
            <li>
              <button onClick={() => setStyle(!style)}>
                <Link to="/spacefound_interiors/contact-us">Contact Us</Link>
              </button>
            </li>
          </ul>
        </div>
      )}
      <button
        type="button"
        id="checkbars"
        className="nav-bars"
        onClick={() => setStyle(!style)}
      >
        <VscThreeBars />
      </button>
    </div>
  );
};

export default Navbar;
